import { useEffect, useState } from "react";
// import Popular from "./popular/page";
import { FaPlay } from "react-icons/fa";

function useMobilePlatform() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobilePlatforms = /android|iphone|ipod|windows phone|blackberry/i;
    const isIOS =
      /iPad|iPhone|iPod/.test(userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

    setIsMobile(mobilePlatforms.test(userAgent) || isIOS);
  }, []);

  return isMobile;
}

export default function Home() {
  const isMobile = useMobilePlatform();

  const handleFullScreen = () => {
    const iframe = document.getElementById("game-iframe");
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  };

  const handleClick = () => {
    window.location.href = "#/game";
  };

  return (
    <div className="relative z-50 bg-[#FFE4C7]">
      {!isMobile ? (
        <div className="2xl:flex lg:flex flex items-center justify-center 2xl:h-[85vh] lg:h-[75vh] h-[65vh] lg:mx-24 mx-8">
          <iframe
            id="game-iframe"
            src="https://moto-x3m.gamestores.fun/"
            width="100%"
            height="100%"
            frameBorder="0"
            title="Moto X3M"
            style={{
              aspectRatio: "16 / 9",
              objectFit: "cover",
              borderRadius: "8px",
              maxWidth: "fit-content",
              border: "2px solid #3D2A7D",
            }}
          ></iframe>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-[35vh] lg:mx-24 mx-8 opacity-90 rounded-lg">
          <div className="relative w-full h-full flex flex-col items-center justify-center">
            <div className="absolute inset-0 bg-playnow opacity-50"></div>
            <button
              className="w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center relative animate-zoom"
              onClick={handleClick}
            >
              <FaPlay className="text-black w-6 h-6" />
            </button>
            <a
              className="poppins-bold font-bold text-[20px] text-white mt-3 relative"
              href="#/game"
              style={{
                textShadow:
                  "1px 1px 2px black, -1px -1px 2px black, 1px -1px 2px black, -1px 1px 2px black",
              }}
            >
              Play now
            </a>
          </div>
        </div>
      )}
      <div className="flex items-center justify-center">
        <div className="container">
          {!isMobile && (
            <div className="2xl:flex lg:flex md:hidden hidden justify-center mt-6 2xl:mb-[40px] lg:mb-[28px] mb-[28px]">
              <button
                onClick={handleFullScreen}
                className="btn-full-screen 2xl:py-6 2xl:px-16 lg:py-3 lg:px-8 md:py-3 md:px-8 px-4 py-2 rounded"
              >
                <span className="lilita-one-regular 2xl:text-4xl lg:text-3xl md:text-3xl text-white">
                  Full Screen
                </span>
              </button>
            </div>
          )}
          {/* <Popular /> */}
          <div className="w-full 2xl:pt-1 lg:pt-1 pt-10 bg-[#F8FFFF]">
            <div className="2xl:mx-56 2xl:mt-14 2xl:pb-14 md:pb-14 md:mt-14 mt-10 lg:mx-20 md:mx-9 mx-6 pb-10">
              <h1 className="2xl:text-[40px] md:text-[40px] text-[32px] lilita-one-regular text-[#FF6F1D]">
                Experience Moto X3M - The Ultimate Thrill Racing Game
              </h1>
              <div className="2xl:mt-6 md:mt-6">
                <div className="text-base md:text-lg 2xl:text-lg lg:text-lg poppins-regular text-[#383838]">
                  Welcome to Moto X3M, the thrilling motorbike racing game where
                  you can showcase your incredible riding skills and conquer
                  challenging tracks. With vibrant graphics, captivating
                  gameplay, and hundreds of unique levels, Moto X3M is the
                  perfect choice for speed enthusiasts and adrenaline junkies
                  alike.
                </div>
              </div>
            </div>
          </div>
          <div className="text-[#383838] lg:px-20 md:px-9 px-6 2xl:pt-20 lg:pt-20 md:pt-14 content-des 2xl:px-56 2xl:pb-20 md:pb-14 pt-14 pb-14">
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Introduction to Moto X3M
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Moto X3M is an action-packed racing game where players control a
              motorbike to navigate through complex terrains. The game features
              increasingly difficult levels, from bumpy roads to spectacular
              stunts. Moto X3M isn’t just a game for fun—it also tests your
              dexterity, agility, and quick reflexes.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Outstanding Features of Moto X3M
            </h2>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
              Rich Level System
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Moto X3M offers over 100 levels, each presenting a fresh
              challenge. You’ll encounter obstacles like spinning wheels,
              tilting bridges, and dangerous traps. As you progress, the levels
              get tougher, keeping you hooked to the screen.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
              Vivid Graphics and Smooth Effects
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              The graphics in Moto X3M are beautifully crafted, featuring bright
              colors and smooth animations. Each setting, from scorching deserts
              to lush green forests, brings a realistic and immersive
              experience.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
              Variety of Unlockable Bikes
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              You can customize or unlock a variety of bikes, from dirt bikes
              and sports bikes to unique and unconventional designs. Each bike
              comes with its own strengths and weaknesses, allowing you to
              choose the best one for every challenge.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
              Simple and Intuitive Controls
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Whether you're a new player or a seasoned gamer, Moto X3M's
              controls are easy to master. With just a few keys, you can steer
              the bike, perform flips, or boost your speed for an exhilarating
              experience.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
              Flexible Game Modes
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Moto X3M supports multiple game modes, including solo play to
              progress through levels and competitive modes to challenge
              friends. This adds an extra layer of fun and competition to every
              race.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Tips for Playing Moto X3M Effectively
            </h2>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
              Stay Focused on the Goal
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Concentration is key in Moto X3M. Always keep an eye on the track
              and anticipate sudden obstacles to react quickly and maintain your
              momentum.
            </div>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Observe Before Moving: Take a moment to assess the level's layout
              and plan your strategy. Learn the Patterns: Some challenges may
              repeat in different levels, so recognize patterns to prepare
              better.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
              Control Speed Wisely
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Speed management is crucial in Moto X3M. While speeding up may
              seem exciting, it’s not always the best choice, especially on
              tricky terrains or when facing traps.
            </div>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Accelerate on Flat Roads: Use maximum speed to quickly pass easy
              sections. Slow Down Near Obstacles: Reduce speed to navigate
              hazards like spinning wheels or steep inclines more effectively.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
              Adapt to the Terrain
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Each level in Moto X3M features unique challenges. Here are some
              strategies for common obstacles:
              <ul
                style={{
                  listStyleType: "disc",
                  marginLeft: "30px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <li>
                  Spinning Traps or Spikes: Time your movement carefully and
                  proceed when it's safe.
                </li>
                <li>
                  Tilting or Broken Bridges: Accelerate quickly to jump gaps or
                  balance carefully on uneven surfaces.
                </li>
                <li>
                  Explosive Traps: Maintain a safe distance and use bursts of
                  speed to avoid being caught.
                </li>
              </ul>
              Moto X3M is an exciting game that’s hard to put down, especially
              for those who love speed and challenges. With diverse gameplay,
              stunning graphics, and captivating obstacles, Moto X3M promises
              hours of exhilarating entertainment.
            </div>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Start your speed adventure today! Play Moto X3M and conquer every
              treacherous track!
            </div>

            <div className="control flex items-center 2xl:mt-10 lg:mt-10 mt-4 md:mt-10">
              <div className="2xl:pl-8 2xl:py-8 lg:pl-8 lg:py-8 px-8 py-8">
                <h2 className="2xl:text-2xl lg:text-2xl md:text-2xl text-2xl font-bold text-start lilita-one-regular">
                  How to play Moto X3M?
                </h2>
                <div className="2xl:text-lg lg:text-lg text-base poppins-regular mt-3">
                  This is how you play Moto X3M:
                  <ul
                    style={{
                      listStyleType: "disc",
                      marginLeft: "30px",
                    }}
                  >
                    <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start mt-4 poppins-regular">
                      Use the W or Up Arrow key to speed up
                    </li>
                    <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start poppins-regular">
                      Use the S or Down Arrow key to break
                    </li>
                    <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start poppins-regular">
                      Use the A and D or Left Arrow and Right Arrow keys to
                      position your motorbike. These keys are crucial for
                      performing front flips and back flips.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
