import { useEffect } from "react";

export default function TermOfUse() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'Terms of Use | Moto X3M';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Review the Terms of Use for Moto X3M to understand our policies on site access, content usage, and user responsibilities!");
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Moto X3M - Play Moto X3M Bike Race Game Unblocked!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Play the challenging Moto X3M game with thrilling racing levels. Experience difficult stages, overcome obstacles, and complete each level to earn high scores. Join now!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://motox3mgame.net/');
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-18vh)] text-[#383838] mb-10">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        Terms of Use for Moto X3M
                    </h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Thank you for visiting Moto X3M. By accessing or using our website, you agree to these Terms of Use. Please read them carefully, as they outline your rights and responsibilities when interacting with our services.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        1. Acceptance of Terms
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        By using Moto X3M, you acknowledge and agree to comply with these Terms of Use, as well as any additional terms that may apply.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        2. Site Access and Use
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        When using our website, you agree to:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                Access the site only for lawful purposes.
                            </li>
                            <li>
                                Refrain from sharing, posting, or distributing any content that is offensive, defamatory, violates the rights of others, or is otherwise unlawful.
                            </li>
                            <li>
                                Avoid engaging in activities that could harm, disrupt, or interfere with the functionality of the site.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        3. Intellectual Property
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        All content on the Moto X3M website, including text, graphics, logos, and media, is the property of Moto X3M or its licensors. Unauthorized use, reproduction, or distribution of any content is strictly prohibited.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        4. User-Generated Content
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        You may contribute content, such as comments or reviews, to our website. By submitting content, you grant Moto X3M a non-exclusive, royalty-free license to use, display, and distribute your submissions. We reserve the right to remove or edit any content deemed inappropriate or in violation of these Terms.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        5. Disclaimers and Limitation of Liability
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        While we strive to provide accurate and up-to-date information, Moto X3M does not guarantee the completeness or accuracy of the content on our site. We are not responsible for any losses, damages, or issues arising from your use of the website or its services.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        6. Termination of Use
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Moto X3M reserves the right to terminate or suspend access to our website at any time without prior notice if these Terms of Use are violated.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        7. Changes to Terms
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We may update these Terms of Use from time to time to reflect changes in our practices. We encourage users to review this page periodically to stay informed of any updates.
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        If you have any questions about these Terms of Use, please contact us at <a href="mailto:support@motox3mgame.net" className=" text-blue-500">{`[support@motox3mgame.net]`}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}