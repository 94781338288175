import { useEffect } from "react";

export default function ContactUs() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'Contact Us | Moto X3M';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Get in touch with the Moto X3M team! Whether you have questions, feedback, or need support, our team is here to help. Contact us now and let's connect!");
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Moto X3M - Play Moto X3M Bike Race Game Unblocked!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Play the challenging Moto X3M game with thrilling racing levels. Experience difficult stages, overcome obstacles, and complete each level to earn high scores. Join now!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://motox3mgame.net/');
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-14vh)] lg:min-h-[calc(100vh-15vh)] md:min-h-[calc(100vh-10vh)] mb-4 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">Contact Us - Moto X3M</h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Dive into the action-packed world of Moto X3M, where adrenaline meets precision. Our platform is dedicated to fans of this exhilarating motorbike racing game, offering tips, tricks, and a vibrant community space to connect with fellow enthusiasts.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Our Mission
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        At Moto X3M, our mission is to empower players to master every jump, flip, and challenge. We strive to create a dynamic community where fans can explore, improve, and fully enjoy the art of extreme racing in the Moto X3M universe.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        What We Offer
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Whether you're a beginner exploring the tracks for the first time or a seasoned rider chasing high scores, Moto X3M has you covered. Here’s what you’ll find:
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Game Guides
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Get step-by-step tutorials and pro strategies to conquer even the toughest levels. Learn how to perform daring stunts and navigate obstacles with ease.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Latest Updates
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Stay in the loop with news on the latest features, new levels, and game enhancements to keep your experience fresh and exciting.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Community Hub
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Join a thriving community of players who share your passion for Moto X3M. Exchange tips, share your high scores, and celebrate victories together.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Exclusive Content
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Gain access to advanced strategies, hidden shortcuts, and expert gameplay tips to elevate your racing skills to the next level.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Connect with Us
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Need help or want to stay updated? We’re here for you:
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Email: <a href="mailto:support@motox3mgame.net" className=" text-blue-500">{`support@motox3mgame.net`}</a>
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Start your journey with Moto X3M today and embrace the thrill of the ultimate motorbike racing adventure!
                    </div>
                </div>
            </div>
        </div>
    )
}