import { useEffect } from "react";

export default function About() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'About Us | Moto X3M';
        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', 'Dive into the exhilarating world of Moto X3M, the ultimate motorbike racing experience. Take on daring tracks filled with challenging obstacles, perform gravity-defying stunts, and race your way to victory!');
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Moto X3M - Play Moto X3M Bike Race Game Unblocked!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Play the challenging Moto X3M game with thrilling racing levels. Experience difficult stages, overcome obstacles, and complete each level to earn high scores. Join now!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://motox3mgame.net/');
            }
        };
    }, []);
    return (
        <div className="content-body mb-10 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="container 2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">Experience Moto X3M - The Ultimate Thrill Racing Game</h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Welcome to Moto X3M, the thrilling motorbike racing game where you can showcase your incredible riding skills and conquer challenging tracks. With vibrant graphics, captivating gameplay, and hundreds of unique levels, Moto X3M is the perfect choice for speed enthusiasts and adrenaline junkies alike.
                    </span>
                    <br />
                    <br />
                    <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
                        Introduction to Moto X3M
                    </h2>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        Moto X3M is an action-packed racing game where players control a motorbike to navigate through complex terrains. The game features increasingly difficult levels, from bumpy roads to spectacular stunts. Moto X3M isn’t just a game for fun—it also tests your dexterity, agility, and quick reflexes.
                    </div>
                    <br />
                    <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
                        Outstanding Features of Moto X3M
                    </h2>
                    <br />
                    <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
                        Rich Level System
                    </h2>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        Moto X3M offers over 100 levels, each presenting a fresh challenge. You’ll encounter obstacles like spinning wheels, tilting bridges, and dangerous traps. As you progress, the levels get tougher, keeping you hooked to the screen.
                    </div>
                    <br />
                    <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
                        Vivid Graphics and Smooth Effects
                    </h2>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        The graphics in Moto X3M are beautifully crafted, featuring bright colors and smooth animations. Each setting, from scorching deserts to lush green forests, brings a realistic and immersive experience.
                    </div>
                    <br />
                    <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
                        Variety of Unlockable Bikes
                    </h2>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        You can customize or unlock a variety of bikes, from dirt bikes and sports bikes to unique and unconventional designs. Each bike comes with its own strengths and weaknesses, allowing you to choose the best one for every challenge.
                    </div>
                    <br />
                    <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
                        Simple and Intuitive Controls
                    </h2>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        Whether you're a new player or a seasoned gamer, Moto X3M's controls are easy to master. With just a few keys, you can steer the bike, perform flips, or boost your speed for an exhilarating experience.
                    </div>
                    <br />
                    <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
                        Flexible Game Modes
                    </h2>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        Moto X3M supports multiple game modes, including solo play to progress through levels and competitive modes to challenge friends. This adds an extra layer of fun and competition to every race.
                    </div>
                    <br />
                    <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
                        Tips for Playing Moto X3M Effectively
                    </h2>
                    <br />
                    <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
                        Stay Focused on the Goal
                    </h2>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        Concentration is key in Moto X3M. Always keep an eye on the track and anticipate sudden obstacles to react quickly and maintain your momentum.
                    </div>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        Observe Before Moving: Take a moment to assess the level's layout and plan your strategy.
                        Learn the Patterns: Some challenges may repeat in different levels, so recognize patterns to prepare better.
                    </div>
                    <br />
                    <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
                        Control Speed Wisely
                    </h2>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        Speed management is crucial in Moto X3M. While speeding up may seem exciting, it’s not always the best choice, especially on tricky terrains or when facing traps.
                    </div>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        Accelerate on Flat Roads: Use maximum speed to quickly pass easy sections.
                        Slow Down Near Obstacles: Reduce speed to navigate hazards like spinning wheels or steep inclines more effectively.
                    </div>
                    <br />
                    <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-2xl md:text-xl">
                        Adapt to the Terrain
                    </h2>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        Each level in Moto X3M features unique challenges. Here are some strategies for common obstacles:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px',
                            marginBottom: '10px'
                        }}>
                            <li>
                                Spinning Traps or Spikes: Time your movement carefully and proceed when it's safe.
                            </li>
                            <li>
                                Tilting or Broken Bridges: Accelerate quickly to jump gaps or balance carefully on uneven surfaces.
                            </li>
                            <li>
                                Explosive Traps: Maintain a safe distance and use bursts of speed to avoid being caught.
                            </li>
                        </ul>
                        Moto X3M is an exciting game that’s hard to put down, especially for those who love speed and challenges. With diverse gameplay, stunning graphics, and captivating obstacles, Moto X3M promises hours of exhilarating entertainment.
                    </div>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        Start your speed adventure today! Play Moto X3M and conquer every treacherous track!
                    </div>
                </div>
            </div>
        </div>
    )
}