import { useEffect } from "react";

export default function DMCA() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'DMCA Policy | Moto X3M Game';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Moto X3M respects copyright laws. Learn about our DMCA policy and how to report any content concerns or copyright violations. Protecting rights and ensuring compliance!");
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Moto X3M - Play Moto X3M Bike Race Game Unblocked!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Play the challenging Moto X3M game with thrilling racing levels. Experience difficult stages, overcome obstacles, and complete each level to earn high scores. Join now!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://motox3mgame.net/');
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-14vh)] lg:min-h-[calc(100vh-15vh)] md:min-h-[calc(100vh-10vh)] mb-4 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        DMCA Policy - Moto X3M Game
                    </h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Moto X3M Game is dedicated to upholding intellectual property rights and fully complies with the Digital Millennium Copyright Act (DMCA). If you believe that any content on this site violates your copyright, please follow the outlined procedure to report it.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Reporting Copyright Infringement
                    </h2>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        To submit a DMCA notice, please include the following details: <br />
                        <br />
                        <strong>Your Contact Information:</strong><br /><br />
                        Provide your full name, mailing address, phone number, and email address.
                        <ul
                            style={{
                                marginLeft: '30px',
                                marginTop: '10px'
                            }}
                            className="numbered-list"
                        >
                            <li>
                                Identification of the Copyrighted Work:
                                Clearly describe the copyrighted work you believe has been infringed.
                            </li>
                            <li>
                                Location of the Infringing Material:
                                Specify the exact URL(s) on Moto X3M Game’s site where the material is located.
                            </li>
                            <li>
                                Statement of Good Faith:
                                Confirm that you believe, in good faith, that the use of the material is unauthorized by the copyright owner, its agent, or the law.
                            </li>
                            <li>
                                Statement of Accuracy:
                                Include a statement that the information in your notice is accurate, and under penalty of perjury, you are authorized to act on behalf of the copyright owner.
                            </li>
                            <li>
                                Signature:
                                Provide a physical or electronic signature of the copyright owner or their authorized representative.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        How to Submit a Notice
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Send your DMCA notice to our designated agent at:
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Email: <a href="mailto:support@motox3mgame.net" className=" text-blue-500">{`support@motox3mgame.net`}</a>
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Upon receiving a valid DMCA notice, we will promptly investigate the claim and take the necessary actions, which may include removing or disabling access to the infringing material. Thank you for helping us protect intellectual property rights.
                    </div>
                </div>
            </div>
        </div>
    )
}