import { useEffect } from "react";

export default function Policy() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'Privacy Policy | Moto X3M';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Review the Terms of Use for Moto X3M Game to understand our policies on site access, content usage, and user responsibilities.");
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Moto X3M - Play Moto X3M Bike Race Game Unblocked!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Play the challenging Moto X3M game with thrilling racing levels. Experience difficult stages, overcome obstacles, and complete each level to earn high scores. Join now!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://motox3mgame.net/');
            }
        };
    }, []);

    return (
        <div className="content-body mb-10 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        Privacy Policy for Moto X3M
                    </h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Welcome to Moto X3M. Protecting your privacy is important to us. This Privacy Policy explains how we collect, use, and safeguard your information while you interact with our website and services. By using our site, you consent to the data collection and usage practices outlined below.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        1. Information Collection
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We may collect personal information such as your name, email address, and usage data to enhance your experience on Moto X3M. This data helps us deliver tailored interactions and improve our services.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        2. Use of Information
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        The information we collect is used to:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                Customize content and provide personalized recommendations.
                            </li>
                            <li>
                                Send updates, newsletters, or promotional offers (if you have opted in).
                            </li>
                            <li>
                                Enhance website functionality through analytics and feedback.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        3. Data Security
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We prioritize the security of your personal information. Our website employs industry-standard security measures to prevent unauthorized access, modification, or disclosure of your data.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        4. Third-Party Services
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Moto X3M may collaborate with third-party service providers to improve our offerings. These providers may collect and process data on our behalf, but they are required to keep your information confidential and secure.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        5. Cookies
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Moto X3M uses cookies to enhance user experience and gather analytics. Cookies help us understand website performance and user behavior. You can disable cookies through your browser settings; however, this may limit certain features on our site.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        6. Your Rights
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        As a user, you have the following rights:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px',
                            marginBottom: '10px'
                        }}>
                            <li>
                                Access, update, or delete your personal information.
                            </li>
                            <li>
                                Opt-out of newsletters and email communications.
                            </li>
                            <li>
                                Request details on how your data is stored and utilized.
                            </li>
                        </ul>
                        If you have any questions about your privacy or wish to exercise your rights, please contact us at <a href="mailto:support@motox3mgame.net" className=" text-blue-500">{`[support@motox3mgame.net]`}</a>. Thank you for trusting Moto X3M!
                    </div>
                </div>
            </div>
        </div>
    )
}